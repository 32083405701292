<template>
    <div>
        <RecycleScroller
            class="scroller"
            :items="items"
            :item-size="32"
            key-field="id"
            v-slot="{ item , index}"
        >
            <div :key="item.id" class="item" :class="{ 'clicked': item.clicked }">
                <button v-if="!item.clicked" :disabled="!((index + 1) % 3 === 0)" @click="clickItem(index)">
                    Click me
                </button>
            </div>
        </RecycleScroller>
    </div>
</template>

<script>
import { ref } from 'vue';
import { RecycleScroller } from 'vue-virtual-scroller'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export default {
  components: {
    RecycleScroller
  },
  setup() {
    const items = ref(Array.from({ length: 100 }, (v, i) => ({
      id: i + 1,
      clicked: false,
    })));
    const clickItem = (index) => {
        items.value[index].clicked = true
    };

    return {
      items,
      clickItem
    };
  }
};
</script>

<style scoped>
button {
    background-color: #054777;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

button:disabled {
    background-color: grey;
}

.scroller {
    height: 100%;
}

.clicked {
    background-color: green !important;
}

.item {
    height: 32px;
    display: flex;
    align-items: center;
}
</style>
